import React from 'react';
import {
  AspectRatio,
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Input,
  IconButton,
  Stack,
  Select,
  Option,
  Typography,
  Card,
  CardActions,
  CardOverflow,
} from '@mui/joy';

import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

import CountrySelector from './CountrySelector';
import {IUser} from '../../interfaces/Auth';

type PersonalInfoProps = {
  user: IUser;
};

const styles = {
  CARD: {
    HEADER: {mb: 1},
    OVERFLOW: {borderTop: '1px solid', borderColor: 'divider'},
    ACTION: {alignSelf: 'flex-end', pt: 2},
  },
  CONTENT: {
    CONTAINER: {my: 1},
    AVATAR: {flex: 1, minWidth: 108, borderRadius: '100%'},
    AVATAR_EDIT: {
      bgcolor: 'background.body',
      position: 'absolute',
      zIndex: 2,
      borderRadius: '50%',
      left: 85,
      top: 180,
      boxShadow: 'sm',
    },
  },
  FORM_CONTROLS: {
    NAME: {
      display: {
        sm: 'flex-column',
        md: 'flex-row',
      },
      gap: 2,
    },
    EMAIL: {flexGrow: 1},
    TIMEZONE: {display: {sm: 'contents'}},
  },
};

const PersonalInfo = (props: PersonalInfoProps) => {
  const {user} = props;

  return (
    <Card>
      <Box sx={styles.CARD.HEADER}>
        <Typography level="title-md">Personal info</Typography>
        <Typography level="body-sm">
          Customize how your profile information will apper to the networks.
        </Typography>
      </Box>
      <Divider />
      <Stack direction="column" spacing={2} sx={styles.CONTENT.CONTAINER}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" spacing={1}>
            <AspectRatio ratio="1" maxHeight={108} sx={styles.CONTENT.AVATAR}>
              <img
                src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286"
                srcSet="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286&dpr=2 2x"
                loading="lazy"
                alt=""
              />
            </AspectRatio>
            <IconButton
              aria-label="upload new picture"
              size="sm"
              variant="outlined"
              color="neutral"
              sx={styles.CONTENT.AVATAR_EDIT}
            >
              <EditRoundedIcon />
            </IconButton>
          </Stack>
          <Stack spacing={1} sx={{flexGrow: 1}}>
            <FormLabel>Name</FormLabel>
            <FormControl sx={styles.FORM_CONTROLS.NAME}>
              <Input
                size="sm"
                placeholder="First name"
                value={user.firstName}
              />
              <Input size="sm" placeholder="Last name" value={user.lastName} />
            </FormControl>
          </Stack>
        </Stack>
        <FormControl>
          <FormLabel>Role</FormLabel>
          <Input size="sm" value={user.roles[0].role} />
        </FormControl>
        <FormControl sx={styles.FORM_CONTROLS.EMAIL}>
          <FormLabel>Email</FormLabel>
          <Input
            size="sm"
            type="email"
            startDecorator={<EmailRoundedIcon />}
            placeholder="email"
            value={user.email}
          />
        </FormControl>
        <div>
          <CountrySelector />
        </div>
        <div>
          <FormControl sx={styles.FORM_CONTROLS.TIMEZONE}>
            <FormLabel>Timezone</FormLabel>
            <Select
              size="sm"
              startDecorator={<AccessTimeFilledRoundedIcon />}
              defaultValue="1"
              value="1"
            >
              <Option value="1">
                Indian Standard Time (IST){' '}
                <Typography textColor="text.tertiary" ml={0.5}>
                  — UTC+05:30
                </Typography>
              </Option>
            </Select>
          </FormControl>
        </div>
      </Stack>
      <CardOverflow sx={styles.CARD.OVERFLOW}>
        <CardActions sx={styles.CARD.ACTION}>
          <Button size="sm" variant="outlined" color="neutral">
            Cancel
          </Button>
          <Button size="sm" variant="solid">
            Save
          </Button>
        </CardActions>
      </CardOverflow>
    </Card>
  );
};

export default PersonalInfo;
