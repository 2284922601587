import * as React from 'react';
import {Box, Input, List, Sheet} from '@mui/joy';

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

import MessageListHeader from './MessageListHeader';
import MessageListItem from './MessageListItem';

import {ChatProps} from '../../../interfaces/Messages';
import {chats} from '../../../mocks/messages';

type MessageListProps = {
  selectedChat: ChatProps;
  setSelectedChat: (chat: ChatProps) => void;
};

const styles = {
  CONTAINER: {
    position: {xs: 'fixed', sm: 'sticky'},
    transform: {
      xs: 'translateX(calc(100% * (var(--MessagesPane-slideIn, 0) - 1)))',
      sm: 'none',
    },
    transition: 'transform 0.4s, width 0.4s',
    zIndex: 100,
    width: '100%',
    top: 52,
  },
  WRAPPER: {
    borderRight: '1px solid',
    borderColor: 'divider',
    height: 'calc(100dvh - var(--Header-height))',
    overflowY: 'auto',
  },
  NEW_MESSAGE: {display: {xs: 'none', sm: 'unset'}},
  MESSAGE_LIST: {
    py: 0,
    '--ListItem-paddingY': '0.75rem',
    '--ListItem-paddingX': '1rem',
  },
};

const MessageList = (props: MessageListProps) => {
  const {selectedChat, setSelectedChat} = props;
  return (
    <Sheet sx={styles.CONTAINER}>
      <Sheet sx={styles.WRAPPER}>
        <MessageListHeader />
        <Box sx={{px: 2, pb: 1.5}}>
          <Input
            size="sm"
            startDecorator={<SearchRoundedIcon />}
            placeholder="Search"
            aria-label="Search"
          />
        </Box>
        <List sx={styles.MESSAGE_LIST}>
          {chats.map((chat) => (
            <MessageListItem
              key={chat.id}
              {...chat}
              setSelectedChat={setSelectedChat}
              selectedChatId={selectedChat.id}
            />
          ))}
        </List>
      </Sheet>
    </Sheet>
  );
};

export default MessageList;
