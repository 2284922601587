export const isValid = (_validation: any, value: any) => {
  const isValidValue = _validation.REGEX?.test(value);

  if (isValidValue) {
    return {
      isError: false,
      message: null,
    };
  } else {
    return {
      isError: true,
      message: _validation.MESSAGE,
    };
  }
};

export const isExist = (name: any, value: any) => {
  if (value.trim()) {
    return {
      isError: false,
      message: null,
    };
  } else {
    return {
      isError: true,
      message: name + ' is required.',
    };
  }
};
