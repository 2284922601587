import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {Box} from '@mui/joy';

import {useAppSelector} from '../../redux';
import {isLoggedIn} from '../../services/AuthService';
import Sidebar from '../../layouts/app/Sidebar';
import SidebarMenu from '../../layouts/app/SidebarMenu';

const styles = {
  WRAPPER: {
    display: 'flex',
    minHeight: '100dvh',
  },
  CONTAINER: {
    pt: {xs: 'calc(12px + var(--Header-height))', md: 3},
    pb: {xs: 2, sm: 2, md: 3},
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
    height: '100dvh',
    gap: 1,
    overflow: 'auto',
  },
};

const AppLayout = () => {
  const auth = useAppSelector((state: any) => state?.auth);
  if (!isLoggedIn(auth)) {
    return <Navigate to="/auth" />;
  }

  return (
    <Box sx={styles.WRAPPER}>
      <Sidebar />
      <SidebarMenu />
      <Box component="main" className="MainContent" sx={styles.CONTAINER}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default AppLayout;
