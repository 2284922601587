import React from 'react';
import {Box, Sheet, Stack} from '@mui/joy';

import MessageList from '../../../components/message/list';
import MessageInbox from '../../../components/message/inbox';

import {ChatProps} from '../../../interfaces/Messages';

const styles = {
  MAIN: {
    flex: 1,
    width: '100%',
    mt: {xs: 'calc(-12px - var(--Header-height))', md: -3},
    mb: {xs: -2, sm: -2, md: -3},
  },
  STACK: {
    mx: 'auto',
    display: 'flex',
  },
  HEADER_ACTIONS: {
    gap: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
  CONTAINER: {
    flex: 1,
    width: '100%',
    mx: 'auto',
    pt: {xs: 'var(--Header-height)', sm: 0},
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      sm: 'minmax(min-content, min(30%, 400px)) 1fr',
    },
  },
};

import {chats} from '../../../mocks/messages';

const Messages = () => {
  const [selectedChat, setSelectedChat] = React.useState<ChatProps>(chats[0]);
  return (
    <Box className="Messages" sx={styles.MAIN}>
      <Stack spacing={4} sx={styles.STACK}>
        <Sheet sx={styles.CONTAINER}>
          <MessageList
            selectedChat={selectedChat}
            setSelectedChat={setSelectedChat}
          />
          <MessageInbox chat={selectedChat} />
        </Sheet>
      </Stack>
    </Box>
  );
};

export default Messages;
