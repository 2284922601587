import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {CssVarsProvider} from '@mui/joy/styles';
import {CssBaseline} from '@mui/joy';
import {Provider} from 'react-redux';
import {ToastContainer} from 'react-toastify';
import {persistStore} from 'redux-persist';
import {PersistGate} from 'redux-persist/integration/react';

import AppThemes from './core/AppThemes';
import AppGlobalStyles from './core/AppGlobalStyles';

import {store} from './redux/Store';

import PublicLayout from './pages/public';
import AuthLayout from './pages/auth';
import AppLayout from './pages/app';

import Home from './pages/public/Home';
import SignIn from './pages/auth/SignIn';

import Dashboard from './pages/app/Dashboard';

import Settings from './pages/app/settings';
import Profile from './pages/app/settings/Profile';
import Messages from './pages/app/messages';

const App = () => {
  const persistor = persistStore(store);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <CssVarsProvider
          defaultMode="dark"
          theme={AppThemes}
          disableTransitionOnChange
        >
          <CssBaseline />
          <AppGlobalStyles />
          <Routes>
            <Route element={<PublicLayout />}>
              <Route path="/" element={<Home />} />
            </Route>
            <Route path="/auth" element={<AuthLayout />}>
              <Route path="" element={<SignIn />} />
            </Route>
            <Route path="/app" element={<AppLayout />}>
              <Route path="" element={<Dashboard />} />

              <Route path="settings" element={<Settings />} />
              <Route path="settings/profile" element={<Profile />} />

              <Route path="messages" element={<Messages />} />
            </Route>
          </Routes>
          <ToastContainer theme="dark" />
        </CssVarsProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
