import * as React from 'react';
import {Badge, Avatar} from '@mui/joy';
import {AvatarProps} from '@mui/joy/Avatar';

type MessageAvatarProps = AvatarProps & {
  online?: boolean;
};

const MessageAvatar = (props: MessageAvatarProps) => {
  const {online = false, ...other} = props;
  return (
    <div>
      <Badge
        color={online ? 'success' : 'neutral'}
        variant={online ? 'solid' : 'soft'}
        size="sm"
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        badgeInset="4px 4px"
      >
        <Avatar size="sm" {...other} />
      </Badge>
    </div>
  );
};

export default MessageAvatar;
