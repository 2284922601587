import React from 'react';
import {Link} from 'react-router-dom';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  GlobalStyles,
  IconButton,
  Input,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  Link as JoyUiLink,
  Typography,
  Sheet,
} from '@mui/joy';

import {listItemButtonClasses} from '@mui/joy/ListItemButton';

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import SupportRoundedIcon from '@mui/icons-material/SupportRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';

import {toast} from 'react-toastify';

import {useAppDispatch, useAppSelector} from '../../redux';
import {useOnLogoutMutation} from '../../redux/features/auth/authApi';
import {clearAuth} from '../../redux/features/auth/authSlice';

import Toggler from './Toggler';
import {closeSidebar} from '../../utils';
import UpgradePlan from '../../components/cards/UpgradePlan';
import AppMoodSwitch from '../../core/AppMoodSwitch';

import {IUserLogout} from '../../interfaces/Auth';
import logo from './../../assets/icons/logo.png';

const styles = {
  CONTAINER: {
    position: {xs: 'fixed', md: 'sticky'},
    transform: {
      xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
      md: 'none',
    },
    transition: 'transform 0.4s, width 0.4s',
    zIndex: 10000,
    height: '100dvh',
    width: 'var(--Sidebar-width)',
    top: 0,
    p: 2,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    borderRight: '1px solid',
    borderColor: 'divider',
  },
  OVERLAY: {
    position: 'fixed',
    zIndex: 9998,
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    opacity: 'var(--SideNavigation-slideIn)',
    backgroundColor: 'var(--joy-palette-background-backdrop)',
    transition: 'opacity 0.4s',
    transform: {
      xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
      lg: 'translateX(-100%)',
    },
  },
  HEADER_LOGO: {display: 'flex', gap: 1, alignItems: 'center'},
  LIST_TOP: {
    gap: 1,
    '--ListItem-radius': '6px',
    '--List-nestedInsetStart': '30px',
  },
  LIST_BOTTOM: {
    mt: 'auto',
    mb: 2,
    gap: 1,
    flexGrow: 0,
    '--ListItem-radius': '6px',
    '--List-nestedInsetStart': '30px',
  },
  USER: {
    PROFILE: {display: 'flex', gap: 1, alignItems: 'center'},
    DETAILS: {minWidth: 0, flex: 1},
  },
};

const Sidebar = () => {
  const [onLogout, {isLoading}] = useOnLogoutMutation();
  const dispatch = useAppDispatch();
  // const {mode} = useColorScheme();

  const {user, refreshToken} = useAppSelector((state: any) => state?.auth);

  const handleLogout = () => {
    const requestData: IUserLogout = {
      email: user.email,
      refreshToken: refreshToken,
    };

    onLogout(requestData)
      .unwrap()
      .then((result) => {
        toast(result?.message);
        dispatch(clearAuth());
      })
      .catch((error) => {
        toast(error?.data?.message || 'Seems like you fucked up!');
      });
  };

  return (
    <Sheet className="Sidebar" sx={styles.CONTAINER}>
      {isLoading && <LinearProgress />}
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={styles.OVERLAY}
        onClick={() => closeSidebar()}
      />
      <Box sx={styles.HEADER_LOGO}>
        <JoyUiLink href="https://subhendu.io">
          <img width={35} src={logo} alt="Subhendu.io" />{' '}
        </JoyUiLink>
        <AppMoodSwitch sx={{ml: 'auto'}} />
      </Box>
      <Input
        size="sm"
        startDecorator={<SearchRoundedIcon />}
        placeholder="Search"
      />
      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <List size="sm" sx={styles.LIST_TOP}>
          <ListItem>
            <ListItemButton role="menuitem" component={Link} to="/app">
              <DashboardRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">Dashboard</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              role="menuitem"
              component={Link}
              to="/app/messages/"
            >
              <QuestionAnswerRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">Messages</Typography>
              </ListItemContent>
              <Chip size="sm" color="primary" variant="solid">
                1
              </Chip>
            </ListItemButton>
          </ListItem>
        </List>

        <List size="sm" sx={styles.LIST_BOTTOM}>
          <ListItem>
            <ListItemButton>
              <SupportRoundedIcon />
              Support
            </ListItemButton>
          </ListItem>

          <ListItem nested>
            <Toggler
              renderToggle={({open, setOpen}) => (
                <ListItemButton onClick={() => setOpen(!open)}>
                  <SettingsRoundedIcon />
                  <ListItemContent>
                    <Typography level="title-sm">Settings</Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{transform: open ? 'rotate(180deg)' : 'none'}}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{gap: 0.5}}>
                <ListItem sx={{mt: 0.5}}>
                  <ListItemButton
                    role="menuitem"
                    component={Link}
                    to="/app/settings/profile"
                  >
                    Profile
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton>Wallet</ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton>Privacy</ListItemButton>
                </ListItem>
              </List>
            </Toggler>
          </ListItem>
        </List>

        <UpgradePlan />
      </Box>
      <Divider />
      <Box sx={styles.USER.PROFILE}>
        <Avatar
          size="sm"
          variant="outlined"
          src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286"
        />
        <Box sx={styles.USER.DETAILS}>
          <Typography level="title-sm">{user.firstName}</Typography>
          <Typography level="body-xs">{user.email}</Typography>
        </Box>
        <IconButton
          onClick={handleLogout}
          size="sm"
          variant="plain"
          color="neutral"
        >
          <LogoutRoundedIcon />
        </IconButton>
      </Box>
    </Sheet>
  );
};

export default Sidebar;
