import {interceptor} from '../../interceptor';

import {API_METHODS, API_ROUTES} from '../../../constants/queries';
import {IUserLogin, IUserLogout, IUserRegister} from '../../../interfaces/Auth';

export const authApi = interceptor.injectEndpoints({
  endpoints: (builder) => ({
    onLogin: builder.mutation({
      query: (body: IUserLogin) => ({
        url: API_ROUTES.PUBLIC.LOGIN,
        method: API_METHODS.POST,
        body,
      }),
    }),
    onRegister: builder.mutation({
      query: (body: IUserRegister) => ({
        url: API_ROUTES.PUBLIC.REGISTER,
        method: API_METHODS.POST,
        body,
      }),
    }),
    onLogout: builder.mutation({
      query: (body: IUserLogout) => ({
        url: API_ROUTES.PRIVATE.LOGOUT,
        method: API_METHODS.POST,
        body,
      }),
    }),
  }),
});

export const {useOnLoginMutation, useOnRegisterMutation, useOnLogoutMutation} =
  authApi;
