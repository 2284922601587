import React from 'react';
import {GlobalStyles} from '@mui/joy';

const AppGlobalStyles = () => {
  return (
    <GlobalStyles
      styles={{
        ':root': {
          '--Form-maxWidth': '800px',
          '--Transition-duration': '0.4s',
        },
      }}
    />
  );
};

export default AppGlobalStyles;
