import React from 'react';
import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Textarea,
  Stack,
  Typography,
  Card,
  CardActions,
  CardOverflow,
} from '@mui/joy';

import EditorToolbar from './EditorToolbar';

import {IUser} from '../../interfaces/Auth';

type BioProps = {
  user: IUser;
};

const styles = {
  CARD: {
    HEADER: {mb: 1},
    OVERFLOW: {borderTop: '1px solid', borderColor: 'divider'},
    ACTION: {alignSelf: 'flex-end', pt: 2},
  },
  FORM: {
    STACK: {my: 1},
    TEXTAREA: {mt: 1.5},
    HELPER_TEXT: {mt: 0.75, fontSize: 'xs'},
  },
};

const Bio = (props: BioProps) => {
  const {user} = props;
  console.log(user);

  return (
    <Card>
      <Box sx={styles.CARD.HEADER}>
        <Typography level="title-md">Bio</Typography>
        <Typography level="body-sm">
          Write a short introduction to be displayed on your profile
        </Typography>
      </Box>
      <Divider />
      <Stack spacing={2} sx={styles.FORM.STACK}>
        <EditorToolbar />
        <Textarea
          size="sm"
          minRows={4}
          sx={styles.FORM.TEXTAREA}
          defaultValue="I'm a software developer based in Bangkok, Thailand. My goal is to solve UI problems with neat CSS without using too much JavaScript."
        />
        <FormHelperText sx={styles.FORM.HELPER_TEXT}>
          275 characters left
        </FormHelperText>
      </Stack>
      <CardOverflow sx={styles.CARD.OVERFLOW}>
        <CardActions sx={styles.CARD.ACTION}>
          <Button size="sm" variant="outlined" color="neutral">
            Cancel
          </Button>
          <Button size="sm" variant="solid">
            Save
          </Button>
        </CardActions>
      </CardOverflow>
    </Card>
  );
};

export default Bio;
