// export const BASE_URL = 'http://localhost:3001/api/v1';
export const BASE_URL = 'https://subhendu-api.onrender.com/api/v1';

export const API_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

export const API_ROUTES = {
  PUBLIC: {
    APP: '/app',
    LOGIN: '/auth/login',
    REGISTER: '/auth/register',
    VERIFY_SMS: '/auth/verification/verify-sms',
    VERIFY_EMAIL: '/auth/verification/verify-email',
    SEND_VERIFICATION_SMS: '/auth/verification/send-sms',
    SEND_VERIFICATION_EMAIL: '/auth/verification/send-email',
  },
  PRIVATE: {
    POSTS: '/app/posts',
    LOGOUT: '/auth/logout',
    REFRESH_TOKEN: '/auth/token/refresh',
  },
  SECURE: {
    VAULTS: '/secure/vaults',
  },
};

export const QUERY_KEYS = {
  LOGIN: ['login'],
  REGISTER: ['register'],
};
