import {IAuthState} from '../interfaces/Auth';

export const isLoggedIn = (auth: IAuthState): boolean => {
  try {
    if (auth.user && auth.refreshToken && auth.accessToken) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
