import {configureStore, combineReducers} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {interceptor} from './interceptor';

import globalSlice from './features/global/globalSlice';
import authSlice from './features/auth/authSlice';
import postsSlice from './features/posts/postsSlice';

const rootReducer = combineReducers({
  [interceptor.reducerPath]: interceptor.reducer,
  global: globalSlice,
  auth: authSlice,
  posts: postsSlice,
});

const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['posts'],
};

const reduxPersistActions = [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER];

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [...reduxPersistActions],
      },
    }).concat(interceptor.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
