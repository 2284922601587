import React from 'react';
import {Box, Stack} from '@mui/joy';

import Header from '../../../layouts/app/Header';
import {IDirectory} from '../../../interfaces/Breadcrumbs';

const styles = {
  MAIN: {flex: 1, width: '100%'},
  STACK: {
    display: 'flex',
    maxWidth: '800px',
    mx: 'auto',
    px: {xs: 2, md: 6},
    py: {xs: 2, md: 3},
  },
};

const directories: IDirectory[] = [
  {
    id: 'settings',
    isActive: true,
    name: 'Settings',
    link: '/app/settings',
  },
];

const Settings = () => {
  return (
    <Box sx={styles.MAIN}>
      <Header directories={directories} />
      <Stack spacing={4} sx={styles.STACK}>
        <h1>Settings</h1>
      </Stack>
    </Box>
  );
};

export default Settings;
