import * as React from 'react';
import {Chip, IconButton, Stack, Typography} from '@mui/joy';
import {useNavigate} from 'react-router-dom';

import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import {toggleMessagesPane} from '../../../utils';

const styles = {
  NEW_MESSAGE: {display: {xs: 'none', sm: 'unset'}},
};

const MessageListHeader = () => {
  const navigate = useNavigate();
  const handleNewMessage = () => {
    navigate('/app/messages/create');
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="space-between"
      p={2}
      pb={1.5}
    >
      <Typography
        fontSize={{xs: 'md', md: 'lg'}}
        component="h1"
        fontWeight="lg"
        endDecorator={
          <Chip
            variant="soft"
            color="primary"
            size="md"
            slotProps={{root: {component: 'span'}}}
          >
            4
          </Chip>
        }
        sx={{mr: 'auto'}}
      >
        Messages
      </Typography>
      <IconButton
        variant="plain"
        aria-label="edit"
        color="neutral"
        size="sm"
        sx={styles.NEW_MESSAGE}
        onClick={handleNewMessage}
      >
        <EditNoteRoundedIcon />
      </IconButton>
      <IconButton
        variant="plain"
        aria-label="edit"
        color="neutral"
        size="sm"
        onClick={() => {
          toggleMessagesPane();
        }}
        sx={{display: {sm: 'none'}}}
      >
        <CloseRoundedIcon />
      </IconButton>
    </Stack>
  );
};

export default MessageListHeader;
