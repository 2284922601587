import React from 'react';
import AppMoodSwitch from '../../core/AppMoodSwitch';
import {Box, useColorScheme} from '@mui/joy';
import Logo from '../../components/common/Logo';

const styles = {
  CONTAINER: {
    py: 3,
    display: 'flex',
    justifyContent: 'space-between',
  },
  LOGO: {gap: 2, display: 'flex', alignItems: 'center'},
};

const Header = () => {
  const {mode} = useColorScheme();

  return (
    <Box component="header" sx={styles.CONTAINER}>
      <Box sx={styles.LOGO}>
        <Logo textColor={mode === 'dark' ? 'white' : 'black'} />
      </Box>
      <AppMoodSwitch sx={{height: 'fit-content'}} />
    </Box>
  );
};

export default Header;
