import React, {
  Dispatch,
  Fragment,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';
import {Box} from '@mui/joy';

const styles = {
  WRAPPER: {
    display: 'grid',
    transition: '0.2s ease',
    '& > *': {
      overflow: 'hidden',
    },
  },
};

const Toggler = ({
  expanded = false,
  renderToggle,
  children,
}: {
  expanded?: boolean;
  children: ReactNode;
  renderToggle: (params: {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
  }) => ReactNode;
}) => {
  const [open, setOpen] = useState(expanded);

  return (
    <Fragment>
      {renderToggle({open, setOpen})}
      <Box sx={[styles.WRAPPER, {gridTemplateRows: open ? '1fr' : '0fr'}]}>
        {children}
      </Box>
    </Fragment>
  );
};

export default Toggler;
