import * as React from 'react';
import {Box, Stack} from '@mui/joy';

import {useAppSelector} from '../../../redux';

import Bio from '../../../components/profile/Bio';
import PersonalInfo from '../../../components/profile/PersonalInfo';
import PortfolioProjects from '../../../components/profile/PortfolioProjects';
import Header from '../../../layouts/app/Header';
import {IDirectory} from '../../../interfaces/Breadcrumbs';

const styles = {
  MAIN: {flex: 1, width: '100%'},
  STACK: {
    display: 'flex',
    maxWidth: '800px',
    mx: 'auto',
    px: {xs: 2, md: 6},
    py: {xs: 2, md: 3},
  },
};

const directories: IDirectory[] = [
  {
    id: 'settings',
    isActive: false,
    name: 'Settings',
    link: '/app/settings',
  },
  {
    id: 'profile',
    isActive: true,
    name: 'Profile',
    link: '/app/settings/profile',
  },
];

const Profile = () => {
  const {user} = useAppSelector((state: any) => state?.auth);

  return (
    <Box sx={styles.MAIN}>
      <Header directories={directories} />
      <Stack spacing={4} sx={styles.STACK}>
        <PersonalInfo user={user} />
        <Bio user={user} />
        <PortfolioProjects user={user} />
      </Stack>
    </Box>
  );
};

export default Profile;
