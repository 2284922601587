import React, {useState} from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  LinearProgress,
  Link,
  Stack,
  Typography,
} from '@mui/joy';
import {toast} from 'react-toastify';

import GoogleIcon from '../../components/icons/GoogleIcon';
import {getEncrypted} from '../../services/EncryptionService';
import {IUserLogin} from '../../interfaces/Auth';
import {useOnLoginMutation} from '../../redux/features/auth/authApi';
import {setAuth} from '../../redux/features/auth/authSlice';
import {useAppDispatch} from '../../redux';
import {InfoOutlined} from '@mui/icons-material';
import {VALIDATIONS} from '../../constants/validations';
import {isValid, isExist} from '../../services/ValidationService';

const styles = {
  main: {
    my: 'auto',
    py: 2,
    pb: 5,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    width: 400,
    maxWidth: '100%',
    mx: 'auto',
    borderRadius: 'sm',
    '& form': {
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
    },
    [`& .MuiFormLabel-asterisk`]: {
      visibility: 'hidden',
    },
  },
};

const INITIAL_LOGIN_VALIDATION_DATA = {
  email: {
    isError: false,
    message: null,
  },
  password: {
    isError: false,
    message: null,
  },
  errorResponse: '',
  errorResponseMessage: '',
};

const SignIn = () => {
  const [onLogin, {isLoading}] = useOnLoginMutation();
  const dispatch = useAppDispatch();

  const [user, setUser] = useState<IUserLogin>({
    email: '',
    password: '',
  });

  const [validationErrors, setValidationErrors] = useState(
    INITIAL_LOGIN_VALIDATION_DATA,
  );

  const onChangeValue = (key: string, value: string) => {
    setValidationErrors(INITIAL_LOGIN_VALIDATION_DATA);
    setUser((data) => ({
      ...data,
      [key]: value,
    }));
  };

  const onValidate = () => {
    const emailValidation = isValid(VALIDATIONS.EMAIL, user.email);
    const passwordValidation = isExist('Password', user.password);

    setValidationErrors((data: any) => ({
      ...data,
      email: emailValidation,
      password: passwordValidation,
    }));

    return !emailValidation.isError && !passwordValidation.isError;
  };

  const handleLogin = () => {
    const isLoginValid = onValidate();
    if (isLoginValid) {
      const _user: IUserLogin = {
        email: user.email,
        password: getEncrypted(user.password),
      };
      onLogin(_user)
        .unwrap()
        .then((result) => {
          dispatch(setAuth(result));
        })
        .catch((error) => {
          toast(error?.data?.message || 'Seems like you fucked up!');
          if (!error?.response) {
            setValidationErrors({
              ...validationErrors,
              errorResponse: error?.data,
              errorResponseMessage: 'No Server Response',
            });
          } else if (error.response?.status === 400) {
            setValidationErrors({
              ...validationErrors,
              errorResponse: error?.data,
              errorResponseMessage: 'Missing Username or Password',
            });
          } else if (error.response?.status === 401) {
            setValidationErrors({
              ...validationErrors,
              errorResponse: error?.data,
              errorResponseMessage: 'Unauthorized',
            });
          } else {
            setValidationErrors({
              ...validationErrors,
              errorResponse: error?.data,
              errorResponseMessage: 'Login Failed',
            });
          }
        });
    } else {
      toast('Seems like you fucked up!');
    }
  };

  return (
    <>
      {isLoading && <LinearProgress />}
      <Box component="main" sx={styles.main}>
        <Stack gap={4} sx={{mb: 2}}>
          <Stack gap={1}>
            <Typography component="h1" level="h3">
              Sign in
            </Typography>
            <Typography level="body-sm">
              New to company?{' '}
              <Link href="#replace-with-a-link" level="title-sm">
                Sign up!
              </Link>
            </Typography>
          </Stack>
          <Button
            variant="soft"
            color="neutral"
            fullWidth
            startDecorator={<GoogleIcon />}
          >
            Continue with Google
          </Button>
        </Stack>
        <Divider
          sx={(theme) => ({
            [theme.getColorSchemeSelector('light')]: {
              color: {xs: '#FFF', md: 'text.tertiary'},
            },
          })}
        >
          or
        </Divider>
        <Stack gap={4} sx={{mt: 2}}>
          <FormControl required error={validationErrors.email.isError}>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              name="email"
              value={user.email}
              onChange={(event: any) => {
                onChangeValue('email', event.target.value);
              }}
            />
            {validationErrors.email.isError && (
              <FormHelperText>
                <InfoOutlined />
                {validationErrors.email.message}.
              </FormHelperText>
            )}
          </FormControl>
          <FormControl required error={validationErrors.password.isError}>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              name="password"
              value={user.password}
              onChange={(event: any) => {
                onChangeValue('password', event.target.value);
              }}
            />
            {validationErrors.password.isError && (
              <FormHelperText>
                <InfoOutlined />
                {validationErrors.password.message}.
              </FormHelperText>
            )}
          </FormControl>
          <Stack gap={4} sx={{mt: 2}}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Checkbox size="sm" label="Remember me" name="persistent" />
              <Link level="title-sm" href="#replace-with-a-link">
                Forgot your password?
              </Link>
            </Box>
            <Button type="button" onClick={handleLogin} fullWidth>
              Sign in
            </Button>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default SignIn;
