import React from 'react';

import {Typography, Link, Breadcrumbs} from '@mui/joy';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import {IDirectory} from '../../interfaces/Breadcrumbs';

const styles = {
  CONTENT: {pl: 0},
};

type BreadcrumbsProps = {
  directories: IDirectory[];
};

const AppBreadcrumbs = (props: BreadcrumbsProps) => {
  const {directories} = props;

  return (
    <Breadcrumbs
      size="sm"
      aria-label="breadcrumbs"
      separator={<ChevronRightRoundedIcon fontSize="small" />}
      sx={styles.CONTENT}
    >
      <Link underline="none" color="neutral" href="/app" aria-label="Home">
        <HomeRoundedIcon />
      </Link>
      {directories.map((directory: IDirectory) => {
        return directory.isActive ? (
          <Typography
            key={directory.id}
            color="primary"
            fontWeight={500}
            fontSize={12}
          >
            {directory.name}
          </Typography>
        ) : (
          <Link
            key={directory.id}
            underline="hover"
            color="neutral"
            href={directory.link}
            fontSize={12}
            fontWeight={500}
          >
            {directory.name}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default AppBreadcrumbs;
