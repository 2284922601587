import React from 'react';
import {
  Button,
  Card,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/joy';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const UpgradePlan = () => {
  return (
    <Card
      invertedColors
      variant="soft"
      color="warning"
      size="sm"
      sx={{boxShadow: 'none'}}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography level="title-sm">Used space</Typography>
        <IconButton size="sm">
          <CloseRoundedIcon />
        </IconButton>
      </Stack>
      <Typography level="body-xs">
        Your team has used 80% of your available space. Need more?
      </Typography>
      <LinearProgress variant="outlined" value={80} determinate sx={{my: 1}} />
      <Button size="sm" variant="solid">
        Upgrade plan
      </Button>
    </Card>
  );
};

export default UpgradePlan;
