import React from 'react';
import {Box, Typography} from '@mui/joy';

const Footer = () => {
  return (
    <Box component="footer" sx={{py: 3}}>
      <Typography level="body-xs" textAlign="center">
        © Subhendu.io # {new Date().getFullYear()}
      </Typography>
    </Box>
  );
};

export default Footer;
