import * as React from 'react';
import Card, {CardProps} from '@mui/joy/Card';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';
import AspectRatio from '@mui/joy/AspectRatio';

import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';

const styles = {
  CARD: {
    borderRadius: 'sm',
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    alignItems: 'center',
    px: 3,
    flexGrow: 1,
    boxShadow: 'none',
  },
  ASPECT_RATIO: {
    minWidth: 32,
    borderRadius: '50%',
    '--Icon-fontSize': '16px',
  },
};

const DropZone = (props: CardProps & {icon?: React.ReactElement}) => {
  const {icon, sx, ...other} = props;

  return (
    <Card
      variant="soft"
      {...other}
      sx={[styles.CARD, ...(Array.isArray(sx) ? sx : [sx])]}
    >
      <AspectRatio
        ratio="1"
        variant="solid"
        color="primary"
        sx={styles.ASPECT_RATIO}
      >
        <div>{icon ?? <FileUploadRoundedIcon />}</div>
      </AspectRatio>
      <Typography level="body-sm" textAlign="center">
        <Link component="button" overlay>
          Click to upload
        </Link>{' '}
        or drag and drop
        <br /> SVG, PNG, JPG or GIF (max. 800x400px)
      </Typography>
    </Card>
  );
};

export default DropZone;
