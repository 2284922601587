import React from 'react';
import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  Card,
  CardActions,
  CardOverflow,
} from '@mui/joy';

import VideocamRoundedIcon from '@mui/icons-material/VideocamRounded';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';

import DropZone from './DropZone';
import FileUpload from './FileUpload';

import {IUser} from '../../interfaces/Auth';

type PortfolioProjectsProps = {
  user: IUser;
};

const styles = {
  CARD: {
    HEADER: {mb: 1},
    OVERFLOW: {borderTop: '1px solid', borderColor: 'divider'},
    ACTION: {alignSelf: 'flex-end', pt: 2},
  },
  STACK: {my: 1},
};

const PortfolioProjects = (props: PortfolioProjectsProps) => {
  const {user} = props;
  console.log(user);

  return (
    <Card>
      <Box sx={styles.CARD.HEADER}>
        <Typography level="title-md">Portfolio projects</Typography>
        <Typography level="body-sm">
          Share a few snippets of your work.
        </Typography>
      </Box>
      <Divider />
      <Stack spacing={2} sx={styles.STACK}>
        <DropZone />
        <FileUpload
          icon={<InsertDriveFileRoundedIcon />}
          fileName="Tech design requirements.pdf"
          fileSize="200 kB"
          progress={100}
        />
        <FileUpload
          icon={<VideocamRoundedIcon />}
          fileName="Dashboard prototype recording.mp4"
          fileSize="16 MB"
          progress={40}
        />
      </Stack>
      <CardOverflow sx={styles.CARD.OVERFLOW}>
        <CardActions sx={styles.CARD.ACTION}>
          <Button size="sm" variant="outlined" color="neutral">
            Cancel
          </Button>
          <Button size="sm" variant="solid">
            Save
          </Button>
        </CardActions>
      </CardOverflow>
    </Card>
  );
};

export default PortfolioProjects;
