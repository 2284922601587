import React from 'react';

import {Box, Button, Modal, ModalDialog, Typography} from '@mui/joy';
import {
  Call,
  // AssignmentInd,
  Facebook,
  Instagram,
  WhatsApp,
} from '@mui/icons-material';

const GetInTouch = (props: any) => {
  const {open, setOpen} = props;

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <ModalDialog
        aria-labelledby="nested-modal-title"
        aria-describedby="nested-modal-description"
        sx={(theme) => ({
          [theme.breakpoints.only('xs')]: {
            top: 'unset',
            bottom: 0,
            left: 0,
            right: 0,
            borderRadius: 0,
            transform: 'none',
            maxWidth: 'unset',
          },
        })}
      >
        <Box sx={{display: 'flex', flex: 1, justifyContent: 'center'}}>
          <img
            style={{width: '100px', borderRadius: 50}}
            src="https://lh3.googleusercontent.com/p/AF1QipPV62TKefJBF-BvUDiTL8u8pe4BYFGKw20hHX-c=s1360-w1360-h1020"
            alt=""
          />
          <Box sx={{paddingX: 2, paddingY: 3}}>
            <Typography level="h3">Subhendu Das</Typography>
            <Typography textColor="text.tertiary">
              <small>hello@subhendu.io</small>
            </Typography>
          </Box>
        </Box>
        <Typography
          sx={{textAlign: 'center', paddingX: 4}}
          textColor="text.tertiary"
        >
          Excited to begin this connection and explore new possibilities
          together!
        </Typography>
        <Box
          sx={{
            mt: 1,
            gap: 1,
            display: 'flex',
            flexDirection: {xs: 'column'},
          }}
        >
          <Button
            variant="solid"
            color="primary"
            onClick={() => {
              window.location.href = 'tel:+919804615141';
            }}
            startDecorator={<Call />}
          >
            Call Me!
          </Button>
          <Button
            variant="solid"
            color="success"
            onClick={() => {
              const phoneNumber = '+919804615141';
              const message =
                'Hello Subhendu, I would like to connect with you!';
              const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
              window.open(url, '_blank');
            }}
            startDecorator={<WhatsApp />}
          >
            Ping me in WhatsApp!
          </Button>
          <Button
            variant="solid"
            color="danger"
            onClick={() => {
              const url = 'https://www.instagram.com/subhendu.io/';
              window.open(url, '_blank');
            }}
            startDecorator={<Instagram />}
          >
            Follow me in instagram!
          </Button>
          <Button
            variant="solid"
            color="primary"
            onClick={() => {
              const url = 'https://www.facebook.com/Subhendu.io/';
              window.open(url, '_blank');
            }}
            startDecorator={<Facebook />}
          >
            Follow me in Facebook!
          </Button>
          {/* <a style={{width: '100%'}} href="./cv.pdf" download>
            <Button
              sx={{width: '100%'}}
              variant="solid"
              color="neutral"
              startDecorator={<AssignmentInd />}
            >
              Download CV
            </Button>
          </a> */}
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Close
          </Button>
        </Box>
      </ModalDialog>
    </Modal>
  );
};

export default GetInTouch;
