import {type PayloadAction, createSlice} from '@reduxjs/toolkit';

import type {IAuthState, IUser} from '../../../interfaces/Auth';
import {getDecrypted, getEncrypted} from '../../../services/EncryptionService';

const initialState: IAuthState = {
  user: null,
  accessToken: null,
  refreshToken: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<any>) => {
      const tokenData = action.payload?._jwt
        ? getDecrypted(action.payload?._jwt)
        : null;
      state.user = action.payload?.user ?? null;
      state.accessToken = tokenData?.accessToken;
      state.refreshToken = getEncrypted(tokenData?.refreshToken);
    },
    setToken: (state, action: PayloadAction<any>) => {
      const tokenData = action.payload?._jwt
        ? getDecrypted(action.payload?._jwt)
        : null;
      state.accessToken = tokenData?.accessToken;
    },
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    clearAuth: () => {
      return initialState;
    },
  },
});

export default authSlice.reducer;

export const {setAuth, setToken, setUser, clearAuth} = authSlice.actions;
