export const THEMES = {
  DARK: 'dark',
  LIGHT: 'light',
};

export const CHARS =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

export const CRYPTO_KEY =
  'B9EbGeKgNjRnTqVtYv2x5A7CaFcHeMhPkRpUrWtZw3y6B8DaGdJfMjQmSpVsXu2x4';
