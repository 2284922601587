import {
  type BaseQueryApi,
  type FetchArgs,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

import {API_METHODS, API_ROUTES, BASE_URL} from '../constants/queries';

import {type IAuthState} from '../interfaces/Auth';
import {setToken, clearAuth} from './features/auth/authSlice';

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, {getState}: any) => {
    headers.set('Content-Type', 'application/json');
    headers.set('Access-Control-Allow-Origin', '*');
    const auth: IAuthState = getState().auth;
    if (auth && auth.accessToken) {
      headers.set('authorization', `Bearer ${auth.accessToken as string}`);
    }
    return headers;
  },
});

const baseQueryWithReAuth = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: object,
): Promise<any> => {
  try {
    let result: any = await baseQuery(args, api, extraOptions);
    if (result?.error?.status === 401) {
      const state: any = api.getState();
      if (state?.auth?.refreshToken) {
        const reAuth: any = await baseQuery(
          {
            url: API_ROUTES.PRIVATE.REFRESH_TOKEN,
            method: API_METHODS.POST,
            body: {
              refreshToken: state?.auth?.refreshToken,
            },
          },
          api,
          extraOptions,
        );

        if (reAuth?.data?._jwt) {
          api.dispatch(setToken(reAuth?.data));
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(clearAuth());
        }
      }
    }
    return result;
  } catch (error) {
    throw error;
  }
};

export const interceptor = createApi({
  baseQuery: baseQueryWithReAuth,
  endpoints: () => ({}),
});
