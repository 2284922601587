export const VALIDATIONS = {
  EMAIL: {
    REGEX: new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/),
    MESSAGE: 'Please enter a valid email address.',
  },
  PASSWORD: {
    REGEX: new RegExp(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    ),
    MESSAGE:
      'Minimum eight characters, at least one letter, one number and one special character',
  },
};
