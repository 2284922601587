import React from 'react';
import {Link, Typography} from '@mui/joy';

import logo from './../../assets/icons/logo.png';

const Logo = (props: any) => {
  const {textColor} = props;

  return (
    <Link
      href="https://subhendu.io"
      sx={{position: 'absolute', top: 30, alignSelf: 'start'}}
    >
      <img width={50} src={logo} alt="Subhendu.io" />{' '}
      <Typography
        sx={{
          marginLeft: 1,
          fontSize: 20,
          color: textColor,
          fontFamily: 'Rubik, sans-serif',
          fontWeight: '500',
        }}
      >
        Subhendu.io
      </Typography>
    </Link>
  );
};

export default Logo;
