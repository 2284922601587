import {type PayloadAction, createSlice} from '@reduxjs/toolkit';

const initialState: any = {
  header: {
    inMenuVisible: false,
  },
};

export const globalSlice = createSlice({
  name: 'test',
  initialState,
  reducers: {
    setHeaderMenuVisibility: (state, action: PayloadAction<any>) => {
      state.header.inMenuVisible = action.payload;
    },
  },
});

export default globalSlice.reducer;

export const {setHeaderMenuVisibility} = globalSlice.actions;
