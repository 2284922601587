import React from 'react';
import {Box, Divider, Typography} from '@mui/joy';

import AppBreadcrumbs from '../../layouts/app/Breadcrumbs';
import {IDirectory} from '../../interfaces/Breadcrumbs';

type HeaderProps = {
  directories: IDirectory[];
  headerActions?: React.JSX.Element;
};

const styles = {
  WRAPPER: {
    position: 'sticky',
    top: {sm: -100, md: -110},
    bgcolor: 'background.body',
    zIndex: 9995,
  },
  CONTAINER: {px: {xs: 2, md: 6}},
  HEADER: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  TITLE: {mt: 1, mb: 2},
  ACTIONS: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
};

const Header = (props: HeaderProps) => {
  const {directories, headerActions} = props;

  return (
    <Box sx={styles.WRAPPER}>
      <Box sx={styles.CONTAINER}>
        <AppBreadcrumbs directories={directories} />
        <Box sx={styles.HEADER}>
          <Typography level="h2" component="h1" sx={styles.TITLE}>
            {
              directories.find((directory: IDirectory) => directory.isActive)
                ?.name
            }
          </Typography>
          <Box sx={styles.ACTIONS}>{headerActions}</Box>
        </Box>
        <Divider />
      </Box>
    </Box>
  );
};

export default Header;
