import React, {useState} from 'react';
import {Box, Button, Grid, Typography} from '@mui/joy';
import {useNavigate} from 'react-router-dom';

import heroImage from './../../assets/images/hero.png';
import Logo from '../../components/common/Logo';
import GetInTouch from '../../components/modals/GetInTouch';

const styles = {
  CONTAINER: {
    px: {xs: 5, md: 10, lg: 30},
    py: {xs: 5, md: 20, lg: 30},
    color: '#f0f8ff',
    minHeight: '100vh',
    background: 'linear-gradient(167deg, #020024, #3e0979 53%, #ff008c)',
  },
  WRAPPER: {
    flexGrow: 1,
    flexDirection: {
      xs: 'column-reverse',
      md: 'row',
    },
  },
  HEADER: {
    color: 'white',
    fontWeight: '500',
    lineHeight: 1.2,
    marginBottom: 2,
    fontSize: {
      xs: 40,
      md: 50,
      lg: 65,
    },
    textAlign: {
      xs: 'center',
      md: 'left',
    },
    fontFamily: 'Rubik, sans-serif',
  },
  SUB_HEADER: {
    color: 'white',
    fontWeight: '200',
    fontFamily: 'Rubik, sans-serif',
    textAlign: {
      xs: 'center',
      md: 'left',
    },
  },
  BUTTON: {
    fontSize: 20,
    fontWeight: '500',
    paddingX: 3,
    paddingY: 1.5,
  },
};

const Home = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleAuth = () => {
    navigate('/auth');
  };

  const handleGetInTouch = () => {
    setOpen(true);
  };

  return (
    <Box sx={styles.CONTAINER}>
      <Logo textColor="white" />
      <Grid container spacing={2} sx={styles.WRAPPER}>
        <Grid xs={12} md={6}>
          <Typography sx={styles.HEADER}>
            Hey, Welcome to <strong>Subhendu.io</strong>
          </Typography>
          <Typography level="h4" sx={styles.SUB_HEADER}>
            I am a Full-Stack Developer focusing on Progressive Web and Mobile
            Applications end to end development with over 7+ years experience.
          </Typography>
          <Grid
            container
            spacing={2}
            sx={{
              marginTop: 4,
              justifyContent: {
                xs: 'center',
                md: 'start',
              },
            }}
          >
            <Grid xs={6}>
              <Button
                onClick={handleGetInTouch}
                color={'success'}
                variant={'solid'}
                sx={styles.BUTTON}
              >
                Get in touch
              </Button>
            </Grid>
            <Grid xs={6}>
              <a style={{width: '100%'}} href="./cv.pdf" download>
                <Button
                  onClick={() => {}}
                  variant={'outlined'}
                  sx={[
                    styles.BUTTON,
                    {
                      color: 'white',
                      borderColor: 'white',
                      '&:hover': {
                        color: 'white',
                        borderColor: '#dd4e32',
                        backgroundColor: '#dd4e32',
                      },
                    },
                  ]}
                >
                  Download CV
                </Button>
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{marginTop: {xs: '60px', md: '-30px'}}} xs={12} md={6}>
          <Button
            onClick={handleAuth}
            variant={'plain'}
            sx={{':hover': {backgroundColor: 'transparent !important'}}}
          >
            <img width={'100%'} src={heroImage} alt="" />
          </Button>
        </Grid>
      </Grid>
      <GetInTouch open={open} setOpen={setOpen} />
    </Box>
  );
};

export default Home;
