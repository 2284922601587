import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {Box} from '@mui/joy';

import {useAppSelector} from '../../redux';
import {isLoggedIn} from '../../services/AuthService';

import Header from '../../layouts/auth/Header';
import Footer from '../../layouts/auth/Footer';

const styles = {
  CONTAINER: {
    width: {xs: '100%', md: '50vw'},
    transition: 'width var(--Transition-duration)',
    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    backdropFilter: 'blur(12px)',
    backgroundColor: 'rgba(255 255 255 / 0.2)',
  },
  SECTION: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100dvh',
    width: '100%',
    px: 2,
  },
  BACKGROUND: {
    height: '100%',
    position: 'fixed',
    right: 0,
    top: 0,
    bottom: 0,
    left: {xs: 0, md: '50vw'},
    transition:
      'background-image var(--Transition-duration), left var(--Transition-duration) !important',
    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
    backgroundColor: 'background.level1',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage:
      'url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8?auto=format&w=1000&dpr=2)',
  },
};

const AuthLayout = () => {
  const auth = useAppSelector((state: any) => state?.auth);
  if (isLoggedIn(auth)) {
    return <Navigate to="/app" />;
  }

  return (
    <>
      <Box
        sx={(theme) => ({
          ...styles.CONTAINER,
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'rgba(19 19 24 / 0.4)',
          },
        })}
      >
        <Box sx={styles.SECTION}>
          <Header />
          <Outlet />
          <Footer />
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          ...styles.BACKGROUND,
          [theme.getColorSchemeSelector('dark')]: {
            backgroundImage:
              'url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831?auto=format&w=1000&dpr=2)',
          },
        })}
      />
    </>
  );
};

export default AuthLayout;
