import {type PayloadAction, createSlice} from '@reduxjs/toolkit';

import {IPostsState} from '../../../interfaces/Posts';

const initialState: IPostsState = {
  postsData: {
    posts: [],
    totalPosts: 0,
    currentPosts: 0,
  },
  pagination: {
    limit: 20,
    skip: 0,
    sort: 'postId',
    order: 'DEC',
  },
};

export const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    setPostsData: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        state.postsData = action.payload;
      }
    },
    setPagination: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        state.pagination = action.payload;
      }
    },
  },
});

export default postsSlice.reducer;

export const {setPostsData, setPagination} = postsSlice.actions;
