import * as React from 'react';
import {Box, Sheet, Stack} from '@mui/joy';

import MessageInboxAvatar from './MessageInboxAvatar';
import MessageInboxHeader from './MessageInboxHeader';
import MessageInboxBubble from './MessageInboxBubble';
import MessageInboxInput from './MessageInboxInput';

import {ChatProps, MessageProps} from '../../../interfaces/Messages';

type MessageInboxProps = {
  chat: ChatProps;
};

const MessageInbox = (props: MessageInboxProps) => {
  const {chat} = props;
  const [chatMessages, setChatMessages] = React.useState(chat.messages);
  const [textAreaValue, setTextAreaValue] = React.useState('');

  React.useEffect(() => {
    setChatMessages(chat.messages);
  }, [chat.messages]);

  return (
    <Sheet
      sx={{
        height: {xs: 'calc(100dvh - var(--Header-height))', lg: '100dvh'},
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'background.level1',
      }}
    >
      <MessageInboxHeader sender={chat.sender} />
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          minHeight: 0,
          px: 2,
          py: 3,
          overflowY: 'scroll',
          flexDirection: 'column-reverse',
        }}
      >
        <Stack spacing={2} justifyContent="flex-end">
          {chatMessages.map((message: MessageProps, index: number) => {
            const isYou = message.sender === 'You';
            return (
              <Stack
                key={index}
                direction="row"
                spacing={2}
                flexDirection={isYou ? 'row-reverse' : 'row'}
              >
                {message.sender !== 'You' && (
                  <MessageInboxAvatar
                    online={message.sender.online}
                    src={message.sender.avatar}
                  />
                )}
                <MessageInboxBubble
                  variant={isYou ? 'sent' : 'received'}
                  {...message}
                />
              </Stack>
            );
          })}
        </Stack>
      </Box>
      <MessageInboxInput
        textAreaValue={textAreaValue}
        setTextAreaValue={setTextAreaValue}
        onSubmit={() => {
          const newId = chatMessages.length + 1;
          const newIdString = newId.toString();
          setChatMessages([
            ...chatMessages,
            {
              id: newIdString,
              sender: 'You',
              content: textAreaValue,
              timestamp: 'Just now',
            },
          ]);
        }}
      />
    </Sheet>
  );
};

export default MessageInbox;
