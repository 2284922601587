import * as CryptoJS from 'crypto-js';
import {CHARS, CRYPTO_KEY} from '../constants/enums';

export const getJwtUser = (token: string) => {
  const jwtData = token.split('.')[1];
  const decodedJwtJsonData = atob(jwtData);
  const userData = JSON.parse(decodedJwtJsonData);

  return userData;
};

export const getEncrypted = (data: any) => {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    CRYPTO_KEY,
  ).toString();

  return encrypted;
};

export const getDecrypted = (data: any) => {
  try {
    const bytes = CryptoJS.AES.decrypt(data, CRYPTO_KEY);
    const decrypted = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decrypted;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getEncryptedHex = (data: any) => {
  const b64 = CryptoJS.AES.encrypt(JSON.stringify(data), CRYPTO_KEY).toString();
  const e64 = CryptoJS.enc.Base64.parse(b64);
  const eHex = e64.toString(CryptoJS.enc.Hex);
  return eHex;
};

export const getDecryptedHex = (cipherText: string) => {
  try {
    const reb64 = CryptoJS.enc.Hex.parse(cipherText);
    const bytes = reb64.toString(CryptoJS.enc.Base64);
    const decrypt = CryptoJS.AES.decrypt(bytes, CRYPTO_KEY);
    const decrypted = JSON.parse(decrypt.toString(CryptoJS.enc.Utf8));
    return decrypted;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const btoa = (input: string) => {
  const str = input;
  let output = '';

  for (
    let block = 0, charCode, i = 0, map = CHARS;
    str.charAt(i | 0) || ((map = '='), i % 1);
    output += map.charAt(63 & (block >> (8 - (i % 1) * 8)))
  ) {
    charCode = str.charCodeAt((i += 3 / 4));

    if (charCode > 0xff) {
      throw new Error(
        "'btoa' failed: The string to be encoded contains characters outside of the Latin1 range.",
      );
    }

    block = (block << 8) | charCode;
  }

  return output;
};

export const atob = (input: string = '') => {
  const str = input.replace(/=+$/, '');
  let output = '';

  if (str.length % 4 === 1) {
    throw new Error(
      "'atob' failed: The string to be decoded is not correctly encoded.",
    );
  }
  for (
    let bc = 0, bs = 0, buffer, i = 0;
    (buffer = str.charAt(i++));
    ~buffer && ((bs = bc % 4 ? bs * 64 + buffer : buffer), bc++ % 4)
      ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
      : 0
  ) {
    buffer = CHARS.indexOf(buffer);
  }

  return output;
};
